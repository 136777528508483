import React, { useState } from "react";
import UserLayout from "../../components/Layout/UserLayout";
import InnerHero from "../../components/InnerHero";

import "./style.css";

const TermsAndConditions = () => {
  const [key, setKey] = useState('home');
  return (
    <>
      <UserLayout>
        <InnerHero
          innerheroclass="aboutBg termscondition"
          title="Terms & Condition"
          column="col-md-12 text-center center-content about-sec"
        />


        <section className="termsbg">
          <div className="container">
            <div className="row">
              <div className="terms-text">
                <h2>
                Terms & Condition
                </h2>
                <p>Falcon Book Writing has created this refund policy to describe the circumstances when Falcon Book Writing will issue a refund, the process for claiming a refund against an order and the liabilities of Falcon Book Writing related to a transaction ending in a refund claim. By registering for any of Falcon Book Writing services, you are stating that you have read and accept all of the terms and conditions outlined in this refund policy. The information Falcon Book Writing collects is used to improve the content of our web site, used to notify consumers about updates to our web site or problems with their request. If you do not want to receive e-mail from us in the future, please let us know by sending an e-mail to us or by writing to us and telling us that you do not want to receive e-mail from our company.</p>
                <h2>Coverage & Scope</h2>
                <p>This refund policy covers the treatment of refunds by Falcon Book Writing and/or a website owned and operated by falcon book writing.</p>
                <p>This refund policy does not apply to the practices of companies that Falcon Book Writing does not own or control, or of persons that Falcon Book Writing does not employ or manage, including any third-party service and/or product providers bound by contract and any third-party websites to which falcon book writing’s website link.</p>
                <h2>Filing a complaint</h2>
                <p>Falcon Book Writing believes that every project starts with a finished project in mind. Therefore, all possible attempts of amicably working out a mutually acceptable solution should be a priority in case of an unsatisfactory service.</p>
                <p>Ultimately, our goal is to a reach a mutually satisfactory solution. If you are unsatisfied for any reason that has caused you to think about filing a refund claim, please a few minutes to write to us at <a href="mailto:info@falconbookwriting.com">info@falconbookwriting.com</a> to initiate a dialog before requesting a refund.</p>
                <p>Refund claims should only be filed if we are unable to come to a mutually satisfactory solution.</p>
                <h2>Eligibility for refund</h2>
                <p>Falcon Book Writing takes every project very seriously and ensures that the same is handled in a professional manner and the client gets the result as per the terms and conditions of the proposal. However, if the client is not happy with the outcome and has gone through the Customer Care Concern resolution process then he can request for refund.</p>
                <h2>Claim Your Refund</h2>
                <p>To claim your refund in accordance with our refund policy eligibility, follow the following steps: You can claim your refund by:</p>
                <p>Sending us an Email on <a href="mailto:info@falconbookwriting.com">info@falconbookwriting.com</a></p>
                <p>As soon as we receive your refund request, we will respond to it at our earliest, once the required analysis is completed we will initiate the process which generally take 20-25 Working Days. After you have received your refund, you will not have any rights to any designs/source code submitted by falcon book writing, the information will be submitted the Copyright Acquisition of the Government Copyright Agencies to maintain legality</p>
                <h2>Applicability of the Delivery Policy</h2>
                <p>Please note that falcon book writing’s delivery commitment is subject to:</p>
                <p><b>Timely payments:</b> A project is not considered to be void if the agreed upon / listed payments are not clear.</p>
                <p><b>Timely communication from the client:</b>Falcon Book Writing is not bound to respect the Refund Policy, if required information for successful completion of the project is not communicated to us in a timely manner. Any delay or failure of the project arising out of such situations cannot be attributed to falcon book writing.</p>
                <p><b>Completeness of information provided by the client:</b>Completeness of information provided by the client: If the information provided to Falcon Book Writing is incomplete and/or complete information/scope of the project is not provided at the beginning of the project, Falcon Book Writing is not bound to maintain its delivery and /or refund commitments.</p>
                <p>Falcon Book Writing will not make any compensation for the delay of delivery under any circumstances, unless there is a special agreement signed with a penalty clause for delay in delivery.</p>
                <h2>Concern Resolutions</h2>
                <p>Client can initiate the concern with company by emailing on <a href="mailto:info@falconbookwriting.com">info@falconbookwriting.com</a></p>
                <p>within the 14 days of project initialization, afterwards no request will be entertained for any kind of claims.</p>
                <h2>Limitation of Liability</h2>
                <p>Falcon Book Writing liability is limited to the value of the portion of the project (as per Falcon Book Writing’s proposal) which stands incomplete at any given point of time. At any point of time, Falcon Book Writing cannot be held responsible for losses arising out of the services delivered / not delivered or the delay in the same. The liability to refund arises only once the project has been cancelled by the client and such cancellation has been communicated to Falcon Book Writing in writing.</p>
                <h2>Changes</h2>
                <p>Falcon Book Writing may at any time, without notice to you and in its sole discretion, amend this policy from time to time. Please review this policy periodically. Your continued use of Falcon Book Writing website after any such amendments signifies your acceptance thereof.</p>
                <h2>Quality Assurance Policy</h2>
                <p>We do our best to meet your requirements and our designers do their best to fulfill your expectations.</p>
                <p>We believe in providing best designs and each of our designs is well researched and well crafted.</p>
                <h2>WE OFFER SATISFACTION GUARANTEE</h2>
                <p>Our unlimited revisions policy is to make sure that you are satisfied. <br/> We aim at exceeding your expectations and strive to accomplish it.</p>
                <p>We do not stop our revisions until you are completely satisfied with your design (number of revisions will be according to your package).</p>
                <h2>DELIVERY POLICY</h2>
                <p>The complete order will be sent to the mentioned account on the date stated on Order Confirmation as well as a confirmation email will also be sent.</p>
                <p>The turnaround time will be according to the package, the minimum time required is 2 business days.</p>
                <p>In case of urgent order, contact our customer support team.</p>
                <h2>RECORD MAINTENANCE</h2>
                <p>Falcon Book Writing keeps the records of finalized designs so that in case of any misplaced order, you will be provided the exact file.</p>
                <h2>CUSTOMER SUPPORT</h2>
                <p>Our customer support is present 5 days a week during office timings to answer all of your concerns and queries, our team will answer your concerns anytime and every time.</p>
                <h2>Communication Policy</h2>
                <p>YOU agree that Falcon Book Writing is not liable for any correspondence from email address (es) other than the ones followed by our own domain i.e. “.@ falconbookwriting.com " or/and any toll-free number that is not mentioned on our website. Falcon Book Writing should not be held responsible for any damage(s) caused by such correspondence. We only take responsibility of any communication through email address (es) under our own domain name or/and via toll free number i.e. already mentioned on Falcon Book Writing Website.</p>
                <p>We are not responsible for any damages caused due to other contact details not provided by us.</p>
                <p>Project activation charges will apply if client fails to respond over a period of 25 days.</p>
                <p>We take full responsibility of all the information provided through our official domains.</p>
                <p>Contacting us regarding our T&C`s policy</p>
                <p>If you have questions or suggestions about this refund policies please e–mail us at <a href="mailto:info@falconbookwriting.com">info@falconbookwriting.com</a>.</p>
              </div>
            </div>
          </div>
        </section>

        

        
      </UserLayout>
    </>
  );
};

export default TermsAndConditions;
